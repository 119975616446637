export default {
    translation: {
        general: {
            searchFor: `Search for `,
            users: `users`,
            components: `components `,
            vendors: `vendors `,
            serials: `serials `,
            dispatches: `dispatches `,
            partCode: `part code `,
            entitlements: `entitlements `,
            orderConfirmations: `order confirmations `,
            procurementOrders: `procurement orders `,
            invoices: `invoices `,
            clients: `clients`,
            serviceAccounts: `service accounts`,
            tags: `Tags`,
            tagRules: `Tag Rules`,
            regions: `regions`,
            serviceCenter: `service centers`,
            teams: `teams`,
            dell_stocks: `dell stocks`,
            addTooltip: `Add tooltip`,
            rackScans: `rack scans`,
            scanLines: `scan lines`,
            tickets: `tickets`,
            importFile: `Import file`,
            uploadCSV: `Upload CSV`,
            uploadExcel: `Upload Excel`,
            downloadExampleFile: `Download example file`,
            scheduler: `scheduler`,
            workbenches: `Workbenches`,
            deviceName: `device name`,
            purchaseOrders: `purchase orders`,
            causes: `causes`,
            tooltips: `tooltips`,
            userIsNotPartOfaTeam: `user is not part of a team`,
            stateAfterIssued: `There are already open dispatches for this serial, click
            to see the details.`,
            stateBeforeIssued: `There is already an open dispatch for this serial, click
            to see the details.`,
            nameOrSku: `Name or SKU`,
            partsDelivered: 'Parts delivered',
            boms: 'boms',
            issue: 'issues',
            clearFilter: 'Clear Filter',
            order: 'Order',
            Addons: 'Addons',
            servers: 'Servers',
            projects: 'Projects',
            impersonatingUser: 'You are impersonating user: ',
            deactiveImpersonation: 'Stop Impersonate Mode',
            notFoundForServiceCenter:
                'No team found for service center "{{sc}}". Please contact your supervisor.',
            shouldSwitchTeam:
                'Please switch to the correct team before proceeding with the issue.',
            procurementOrder: `Procurement order`,
            procurementOrderLines: `Procurement order lines`,
            userInformation: `User Information`,
            buyer: `Buyer`,
            supplier: `Supplier`,
        },
        login: {
            loginButton: `Login`,
            welcomeTitle: `Welcome to easy4Device!`,
        },
        breadCrumb: {
            add: `Add`,
            Dashboard: `Dashboard`,
            Issues: `Issues`,
            BOM: `BOM`,
            Tickets: `Tickets`,
            Issues_Detail: `Issues Detail`,
            Overview: `Overview`,
            OpenIssue: `Issue`,
            Dispatches: `Dispatches`,
            Archive: `Archive`,
            Images: `Images`,
            Fleet: `Fleet`,
            Vendor: `Vendor`,
            Serial: `Serial`,
            MicrosoftWindow: `Microsoft window`,
            MicrosoftChrome: `Microsoft chrome`,
            GoogleDomain: `Google domain`,
            Models: `Models`,
            Swaps: `Swaps`,
            Parts: `Parts`,
            Components: `Components`,
            PartCode: `Part codes`,
            Products: `Products`,
            Knowledge: `Knowledge`,
            Claims: `Claims`,
            RenumerationCheck: `Renumeration check`,
            ClaimInvoices: `Claim invoices`,
            Disputes: `Disputes`,
            Procurement: `Procurement`,
            PurchaseOrders: `Purchase Orders`,
            OrderConfirmations: `Order Confirmations`,
            ProcurementOrders: `Procurement Orders`,
            Invoices: `Invoices`,
            Deliveries: `Deliveries`,
            RMA: `RMA`,
            Production: `Production`,
            SystemImages: `System Images`,
            Projects: `Projects`,
            Orders: `Orders`,
            Organizations: `Organizations`,
            Administrator: `Administrator`,
            Users: `Users`,
            DisabledUser: `Disabled Users`,
            Roles: `Roles`,
            System: `System`,
            ErrorLog: `Error log`,
            Api: `API's`,
            Definitions: `Definitions`,
            Causes: `Causes`,
            Create: `Create`,
            ServiceAccounts: `Service Accounts`,
            OrderLines: `Order lines`,
            NamingTags: `Naming Tags`,
            Regions: `Regions`,
            ServiceCenter: `Service centers`,
            Teams: `Teams`,
            Tooltips: `Tooltips`,
            rackScans: `Rack scan`,
            scan: `Scan`,
            ticket: `Ticket`,
            dellStock: `Dell stock`,
            settingManagement: `Setting & Management`,
            ImportDevices: `Import devices`,
            dataScheduler: `Data scheduler`,
            workbenches: `Workbenches`,
            camera: `Camera`,
            Test_Data_Diagnose: 'Test Data Diagnose',
            Test_Data_Dispatch_Shipment_Report:
                'Test Data Dispatch Shipment Report',
            Addons: 'Addons',
            Servers: 'Servers',
        },
        button: {
            export: `Export`,
            import: `Import`,
            action: `Action`,
            exportSerialQueue: `Export Serial Queue`,
            importSerialQueue: `Import Serial Queue`,
            delete: `Delete`,
            disable: `Disable`,
            enable: `Enable`,
            add: `Add`,
            added: `Added`,
            create: `Create`,
            edit: `Edit`,
            close: `Close`,
            save: `Save`,
            closeAndSave: `Close and Save`,
            next: `Next`,
            uploadPicture: `Upload Picture`,
            capturePicture: `Capture Picture`,
            newAddress: `New Address`,
            cancel: 'Cancel',
            yes: 'Yes',
            no: 'No',
            print: 'Print',
            accept: 'Accept',
            deny: 'Deny',
            confirm: 'Confirm',
            snooze: 'Snooze',
            select: 'Select',
            switchTeam: 'Switch Team',
            switchNameTeam: 'Switch {{name}} Team',
            importLenovoDispatch: `Import Lenovo Dispatches`,
            exportAllDispatches: 'Export All Dispatches',
            exportLenovoDispatches: 'Export Lenovo Dispatches',
        },
        dialog: {
            deleteUser: `Delete user`,
            deleteUserContent: `Are you sure to delete this user?`,
            deletePicture: `Delete picture`,
            deletePictureContent: `Are you sure to delete this picture?`,
            deleteDocument: `Delete document`,
            deleteDocumentContent: `Are you sure to delete this document?`,
            deletePictureReject: `Can not delete image. There is at least one instance of the image currently in use.`,
            deleteOrderLine: `Delete Order Line`,
            deleteOrderLineContent: `Are you sure to delete this order line?`,
            cancel: `Cancel`,
            delete: `Delete`,
            close: `Close`,
            confirm: `Confirm`,
        },
        bodyContent: `Body content`,
        profileSetting: {
            settingManagement: `Setting & management`,
            toggleDarkmode: `Toggle Darkmode`,
            signOut: `Sign out`,
            openSetting: `Open setting`,
        },
        navbar: {
            User: `User`,
            ACL: `ACL`,
            Roles: `Roles`,
            Permissions: `Permissions`,
            Device: `Device`,
            Fleet: `Fleet`,
            Overview: `Overview`,
            Vendor: `Vendor`,
            Serials: `Serials`,
            Dispatches: `Dispatches`,
            Component: `Component`,
            Product: `Product`,
            partCode: `Part code`,
            inbox: `Inbox`,
            starred: `Starred`,
            sendEmail: `Send email`,
            drafts: `Drafts`,
            allMail: `All mail`,
            trash: `Trash`,
            spam: `Spam`,
        },
        message: {
            authenticationFail: `Authentication failed`,
            waitingTitle: `Thank you for applying for an account.`,
            login_disabled_account: `Your account has been disabled. Please contact your system administrator.`,
            login_rejected_account: `Your account has been rejected. Please contact your system administrator.`,
            login_pending_account: `Your account is currently pending approval by
            system administrator.`,
            sent_request_approval: `Thank you for applying for an account. Your account is currently pending approval by
            system administrator.`,
            approved: `This account has been approved.`,
            rejected: `This account has been rejected.`,
            resend: `This account has been re-invited.`,
            successInviteUser: `Invited user successfully.`,
            somethingWrong: `Oops! Something went wrong. Please try again later.`,
            existedUser: `Existed user:`,
            Notfound: `No results found`,
            deleteUser: `This user has been deleted successfully`,
            createCauseSuccessfully: `The cause has been created successfully`,
            updateCauseSuccessfully: `The cause has been update successfully`,
            disableAccount: `This user has been disabled`,
            userNotTeam:
                'This user is not in any team. Please add a team first, then you can approve.',
            setTeamForUserSuccessful:
                'User has been successfully assigned to the team.',
            setTeamForUserFailed:
                'Failed to assign user to the team. Please try again.',
            activeTeamSuccessful: 'Active team switched successfully.',
            activeTeamFailed:
                'Failed to switch the active team. Please try again.',
            removeTeamSuccessful: 'Removed team successfully.',
            removeTeamFailed: 'Failed to remove team. Please try again.',
            userNotMember: 'This user is not a member of this team.',
            disableSchedule: `This scheduler has been disabled`,
            addSerialSuccessfully: `Added successfully serial number`,
            searchSerial: `No serial number found for `,
            updateSuccess: `Update successfully`,
            createSuccess: `Create successfully`,
            importSuccess: `Import successfully`,
            importFailed: `Import failed`,
            noTeam: 'User has not been added to a team yet',
            refreshSucess: `Refresh success`,
            refreshFailed: `Refresh failed`,
            you_do_not_have_permission: `You do not have permission to access this resource.`,
            diagnosticIncorrect: `Diagnostic ID incorrect. Please check again!`,
            attachSuccess: `Attach part code into model success!`,
            attachFailed: `Attach part code into model failed!`,
            data_not_found: `Data not found`,
            selectCamera: `Select camera`,
            dashboard: {
                scanSerial: {
                    notFoundTicket: 'No ticket related to this serial.',
                    notNewStateTicket: "No 'NEW' state tickets found.",
                    notFoundSerial: 'This serial is invalid.',
                },
            },
            newVersionAvailable: 'New Version Available',
            newVersionBody:
                'A new version of the app is available. Press Confirm to reload.',
            ppidAttemptedCharacter:
                'PPID attempted character limit only accept 20 to 23 characters',
            terminateImpersonateMode:
                'Authorization failed. Impersonation Mode has been stopped.',
            issueCanceled: 'Issue canceled!',
            activeTeamAndGo: 'Team switch completed. Moving on',
            partNumberIsNotValid:
                'Part number character must not contain space or too long',
            importCompeltedWithFailed: `Import completed with some failed`,
            pleaseSelectExcelType: `Please select *.xlsx file`,
        },
        pages: {
            setting: {
                settingManagement: `Setting and Management`,
                locale: `Locale`,
                timezone: `Timezone`,
            },
            userTeam: {
                id: 'Id',
                name: 'Name',
                description: 'Description',
                serviceCenter: 'Service Center',
                created_at: 'Created at',
                active: 'Active',
                action: 'Action',
            },
            user: {
                id: `ID`,
                user: `User`,
                status: `Status`,
                role: `Role`,
                search: `Search`,
                invite: `Invite`,
                fullname: `Full name`,
                email: `Email`,
                all: `All`,
                disabledDate: `Disabled date`,
                team: `Team`,
                workbench: `Workbench`,
                userDetail: {
                    teams: 'Teams',
                    noTeams: 'No Teams',
                    workbench: `Workbench`,
                    organizationalUnit: `Organizational unit`,
                    resetPassword: `reset password`,
                    updateUser: `update user`,
                    userData: `User data`,
                    emailAddress: `Email address`,
                    phone: `Phone`,
                    security: `Security`,
                    passwordSetting: `Password setting`,
                    groups: `Groups`,
                    noGroup: `No group`,
                    approve: `Approve`,
                    reject: `Reject`,
                    pending: `pending`,
                    active: `active`,
                    rejected: `rejected`,
                    invited: `invited`,
                    disabled: `disabled`,
                    all: `all`,
                    resend: `Resend`,
                    role: `Role`,
                    save: `Save`,
                    timezone: `Timezone`,
                    joinTeam: 'Join Team',
                    switchTeam: 'Switch active team',
                    confirmActiveTeam: `Do you want to switch the active team to {{team}} team?`,
                    confirmRemoveTeam: `Do you want to remove this user from {{team}} team?`,
                    impersonate: 'Impersonate',
                },
                inviteUser: {
                    inviteUser: `Invite user`,
                    invite: `Invite`,
                },
                roleUser: {
                    admin: `Administrator`,
                    user: `User`,
                    all: `All`,
                },
                roleDetail: {
                    name: `Name`,
                    guard_name: `Guard name`,
                    permissions: `Permissions`,
                    save: `Save`,
                },
            },
            device: {
                intune: {
                    tenant_id: 'Tenant ID',
                    tenant_domain: 'Tenant Domain',
                    bios: 'Bios',
                    profile: 'Profile',
                    ip_source: 'IP',
                    created_at: 'Created at',
                },
                fleet: {
                    table: {
                        Vendor: `Vendor`,
                        All: `All`,
                        Category: `Category`,
                        Family: `Family`,
                        Name: `Name`,
                        Oldest: `Oldest`,
                        Newest: `Newest`,
                        In_warranty: `In warranty`,
                        Out_of_warranty: `Out of warranty`,
                        Expired_this_year: `Expired this year`,
                        Active: `Active`,
                        Stock: `Stock`,
                        Other: `Other`,
                        count: `#`,
                        Tags: `Tags`,
                    },
                },
                serial: {
                    overview: {
                        Id: 'Id',
                        Vendor: `Vendor`,
                        Serial: `Serial`,
                        All: `All`,
                        Family: `Family`,
                        Name: `Name`,
                        Shipdate: `Shipdate`,
                        State: `State`,
                        Warranty_End: `Warranty End`,
                        Entitlements: `Entitlements`,
                        brand: `Brand`,
                        initial_state: 'Initial State',
                    },
                    detail: {
                        Vendor: `Vendor`,
                        Family: `Family`,
                        Model: `Model`,
                        lastUpdate: `Last update`,
                        latestEntitlementEndDate: `Latest entitlement end date`,
                        Description: `Description`,
                        Supplier: `Supplier`,
                        Invoice: `Invoice`,
                        localShipping: `Local shipping`,
                        deviceInformation: `Device information`,
                        shipDate: `Ship date`,
                        countryCode: `Country code`,
                        productCode: `Product code`,
                        name: `Name`,
                        Category: `Category`,
                        notebook: `Easy4u Notebook Id`,
                        orderbuil: `Order buid`,
                        localChanel: `Local chanel`,
                        productSKU: `Product SKU`,
                        createdAt: `Created at`,
                        entitlements: `Entitlements`,
                        id: `Id`,
                        itemNumber: `Item number`,
                        type: `Type`,
                        slaCode: `Sla Code`,
                        slaGroup: `Sla Group`,
                        group: `Group`,
                        start: `Start`,
                        end: `End`,
                        description: `Description`,
                        Components: `Components`,
                        partNumber: `Part Number`,
                        itemDescription: `Item Description`,
                        serializable: `Serializable`,
                        Revision: `Revision`,
                        Returnable: `Returnable`,
                        needSerial: `Needs Serial`,
                        replaceableParts: `Replaceable parts`,
                        code: `Code`,
                        procurementData: `Procurement Data`,
                        orderNr: `Order Number`,
                        vendorId: `Vendor Id`,
                        vendorSku: `Vendor SKU`,
                        supplierSku: `Supplier SKU`,
                        trcSku: `TRC SKU`,
                        invoicedAt: `Invoiced at`,
                        shipping: `Shipping`,
                        updatedAt: `Updated at`,
                        procurementInvoiceId: `Procurement Invoice Id`,
                        procurementDispatchId: `Procurement Dispatch Id`,
                        procurementDispatchLineId: `Procurement Dispatch Line Id`,
                        processed: `Processed`,
                        productNumber: `Product number`,
                        productName: `Product name`,
                        tags: `Product tag(s)`,
                        unitPrice: `Unit price`,
                        purchaseOrder: `Purchase order`,
                        pkid: `PKID`,
                        express_servicecode: `Express service code`,
                        issues: `Issues`,
                        ticket_id: 'Ticket id',
                        service_id: 'Service id',
                        service_center_tid: 'Service center tid',
                        brand: 'Brand',
                        status: 'Status',
                        state: 'State',
                        school_id: 'School id',
                        device_user_id: 'Device user id',
                        contract_id: 'Contract id',
                        damaged: 'Damaged',
                        damage_description: 'Damage description',
                        powers_on: 'Powers on',
                        cause_tid: 'Cause tid',
                        accessories: 'Accessories',
                        liquid_damage: 'Liquid damage',
                        loaner_id: 'Loaner id',
                        webshop_id: 'Webshop id',
                        image_version_tid: 'Image version tid',
                        language: 'Language',
                        entity_tid: 'Entity tid',
                        reject_reason: 'Reject reason',
                        previous_id: 'Previous id',
                        created_at: 'Created at',
                        serial: 'Serial',
                        save: 'Save',
                        attachments: 'Attachments',
                        action: 'Action',
                        qty: 'Qty',
                        ppid: `PPID`,
                        causes: `Causes`,
                        broken: `Broken`,
                        deviceIntuneCheck: 'Intune Check',
                        deviceDispatches: `Dispatches`,
                        malicious_damage: 'Malicious Damage',
                        condition: 'Condition',
                        device_information: 'Device information',
                        additionalDescription: `Additional Description`,
                        additionalDescriptionRequiredMsg:
                            'Additional description is required field.',
                    },
                },
                importDevices: {
                    pageTitle: `Import devices`,
                    importSerialQueue: `Import Serial Queue`,
                    importDellCoep: `Import Dell COEP Export`,
                },
            },
            diagnostic: {
                pageName: 'Diagnostic for issue {{issueId}}',
                name: 'Name',
                id: 'id',
                info: 'Info',
                value: 'Value',
                warranty_code: 'Warranty Code',
                warranty_start: 'Warranty Start',
                warranty_end: 'Warranty End',
                sphere: 'Sphere',
                ticket_details: 'Ticket Details',
                pictures: 'Pictures',
                software_diagnostics: 'Software Diagnostics',
                bios_version: 'BIOS Version',
                bios_info: 'BIOS information',
                bios_vendor: 'BIOS vendor',
                bios_date: 'BIOS date',
                bios_revision: 'BIOS revision',
                battery_state: 'Battery state',
                battery_status: 'Battery status',
                battery_capacity: 'Battery capacity',
                battery_capacity_level: 'Battery capacity level',
                power_check_not_found: 'Power check not found!',
                HID_result: 'HID result',
                HID_check_not_found: 'HID check not found!',
                device: 'Device',
                yes: 'Yes',
                no: 'No',
                powers_on: 'Powers On',
                liquid_damage: 'Liquid Damage',
                liquid_damage_total_loss: 'Liquid Damage Total Loss',
                accidental_damage: 'Accidental Damage',
                software_issue: 'Software Issue',
                bios_updated: 'Updates Performed',
                malicious_damage: 'Malicious Damage',
                complaint_found: 'Complaint Found',
                service_id: 'Service Id',
                device_sku: 'Device Sku',
                image_version: 'Image Version',
                device_model: 'Device Model',
                serial: 'Serial',
                diagnosticRootCause: `Diagnostic Root Cause`,
                diagnosticsDescription: `Diagnostics Description`,
                liquid_damage_change: `Liquid Damage Change`,
                technicianComments: `Technician Comments`,
                additionalDescription: `Additional Description`,
                partToChange: `Part To Change`,
                issueLog: `Issue Log`,
                date: `Date`,
                startTime: `Start Time`,
                endTime: `End Time`,
                status: `Status`,
                description: `Description`,
                deviceHistory: `Device History`,
                issueNo: `Issue No`,
                problem: `Problem`,
                partCode: `Part Code`,
                partName: `Part Name`,
                partType: `Part Type`,
                warrantyCode: `Warranty Code`,
                warrantyDescription: `Warranty Description`,
                customer_complaint: `Customer Complaint`,
                enroll_state: `Enroll state`,
                add_to_dispatch: 'Add To Dispatch',
                ticketNumber: 'Ticket number',
                cosmetic_damage: 'Cosmetic Damage',
                isRequire: ' is required field!',
                warranty: `Warranty`,
                accidental: 'Accidental',
                malicious: 'Malicious',
                conditionRequired: 'Condition is required field',
                conditionNotMatch: `The {{condition}} condition is required at least once due the diagnostics`,
                esdLocation: 'Esd Location',
                invalidEsdLocation: 'Invalid ESD Location',
                uploadPictures: 'Upload pictures',
                uploadPicture: 'Upload Picture',
                addPictures: 'Add pictures',
                serviceCenter: 'Service Center',
                entitlements: 'Entitlements',
                additionalDescriptionRequiredMsg:
                    'Additional description is required field.',
                confirmPopupTitle: 'Issue diagnostic confirmation',
                scanEsd: 'Scan ESD',
                button: {
                    totalLoss: 'Total Loss',
                    moveToQC: 'Move to QC',
                    moveToImage: 'Move to Image',
                },
                BIOS: `BIOS`,
                schoolID: `School ID`,
                projectID: `Project ID`,
                timestamp: `Timestamp`,
                lastImageBefore: `Last image before repair completed`,
                firstImageAfter: `First image after repair completed`,
                onePicMsg: 'Please select at least one picture to dispatch',
            },
            issues: {
                rackScans: {
                    scan: {
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            serviceCenter: `Service Center`,
                            team: `Team`,
                            createBy: `Created by`,
                            created: `Created`,
                            updated: `Last Update`,
                        },
                        create: {
                            location: `Location`,
                            ticket: `Ticket`,
                            save: `Save`,
                            name: `Name`,
                            serviceCenter: `Service center`,
                            team: `Team`,
                        },
                        detail: {
                            rackScan: `Rack Scan`,
                            rackScanLine: `Rack scan line`,
                            id: `Id`,
                            location: `Location`,
                            ticket: `Ticket`,
                            action: `Action`,
                        },
                    },
                    ticket: {
                        overview: {
                            ticket: `Ticket`,
                            esdLocation: `Esd location`,
                            lastScanLocation: `Last scan location`,
                            team: `Team`,
                            ticketCreated: `Ticket created`,
                            status: `Status`,
                            state: `State`,
                            dispatches: `Dispatches`,
                            updatedAt: `Updated at`,
                            dispatchStatus: `Dispatch status`,
                            ticketStatus: 'Ticket status',
                            lastScan: `Last scan`,
                            issue: `Issue`,
                        },
                    },
                },
                create: {
                    ticket_id: 'Ticket id',
                    esd_location: 'ESD Location',
                    name: 'Name',
                    description: 'Description',
                    service_id: 'Service id',
                    service_center_tid: 'Service center tid',
                    brand: 'Brand',
                    status: 'Status',
                    state: 'State',
                    school_id: 'School id',
                    device_user_id: 'Device user id',
                    contract_id: 'Contract id',
                    damaged: 'Damaged',
                    damage_description: 'Damage description',
                    powers_on: 'Powers on',
                    cause_tid: 'Cause tid',
                    accessories: 'Accessories',
                    liquid_damage: 'Liquid damage',
                    loaner_id: 'Loaner id',
                    webshop_id: 'Webshop id',
                    image_version_tid: 'Image version tid',
                    language: 'Language',
                    entity_tid: 'Entity tid',
                    reject_reason: 'Reject reason',
                    previous_id: 'Previous id',
                    created_at: 'Created at',
                    serial: 'Serial',
                    save: 'Save',
                    diagnostic: `View diagnostic`,
                    repair: 'Repair',
                    bom_state: 'Bom state',
                    cancelIssue: 'Cancel Issue',
                    yes: 'Yes',
                    no: 'No',
                    confirmCancel:
                        'Are you sure you want to cancel this issue?',
                    process_supervisor_issue: 'Proceed Issue',
                    dispatch_status: 'Dispatch Status',
                    dispatch_id: 'Dispatch id',
                    service_center: 'Service center',
                },
                overview: {
                    id: `Id`,
                    name: `Name`,
                    description: `Description`,
                    ticket_id: 'Ticket id',
                    esd_location: 'ESD Location',
                    service_id: 'Service id',
                    service_center_tid: 'Service center tid',
                    brand: 'Brand',
                    status: 'Status',
                    state: 'State',
                    school_id: 'School id',
                    device_user_id: 'Device user id',
                    contract_id: 'Contract id',
                    damaged: 'Damaged',
                    damage_description: 'Damage description',
                    powers_on: 'Powers on',
                    liquid_damage: 'Liquid damage',
                    cause_tid: 'Cause tid',
                    accessories: 'Accessories',
                    loaner_id: 'Loaner id',
                    webshop_id: 'Webshop id',
                    image_version_tid: 'Image version tid',
                    language: 'Language',
                    entity_tid: 'Entity tid',
                    reject_reason: 'Reject reason',
                    previous_id: 'Previous id',
                    created_at: 'Created at',
                    updated_at: 'Updated at',
                    issues: 'Issues',
                    add: 'Add',
                    add_diagnostic: 'Add diagnostic',
                    action: 'Action',
                    scan_serial: 'Scan serial',
                    general: `General`,
                    diagnostics: `Diagnostics`,
                    repair: `Repair`,
                    qualityCheck: `Quality Check`,
                    report: `Report`,
                    triage: `Triage`,
                    image: `Images`,
                    wo_status: 'Work order status',
                    serial: 'Serial',
                    issue: 'Issue',
                    ticket_status: 'Ticket status',
                    accidential_damage_warranty: 'Accidential damage warranty',
                    battery_warranty_until: 'Battery warranty until',
                    subcription_end_date: 'Subcription end date',
                    not_expert_right_message:
                        "You're not authorized to handle this ticket, please report to supervisor",
                    has_expert_right_message:
                        'This ticket needs your expertise. Please handle it as soon as possible.',
                    accept_qc: 'Accept QC',
                    device_is_total_loss: 'Device is total loss',
                    continue_current_issue: 'Continue current issue',
                    create_new_issue: 'Create a new issue',
                    create_new_issue_successfully:
                        'A new issue was created successfully.',
                    accept_qc_successfully: 'This ticket was QC done.',
                    make_device_is_loss_successfully:
                        'This device is a total loss.',
                    reset_issue_successfully: 'ReOpen issue successfully',
                    error_when_proceeding:
                        'There was an error processing your request.',
                    save: 'Save',
                    cancel: 'Cancel',
                    comment: 'Comment',
                    error_not_found_diagnostic:
                        "Diagnostic not found or you don't have permission to view this page.",
                    deny_diagnose_message: 'Issue is not allowed to diagnose.',
                    deny_repair_message:
                        'Repair not yet started. Please finish diagnostic and part order process to start repair.',
                    deny_qc_message: 'Issue state is not allowed to be in QC.',
                    coming_soon: 'Coming soon',
                    start_triage: 'Start triage',
                    deny_image_message:
                        'Issue state is not allowed to be in Images.',
                    changeTeam: 'Switch to team',
                },
                qualityCheck: {
                    qcForm: {
                        qcForm: 'QC Form',
                        miscellaneous: 'Miscellaneous',
                        comment: 'Comment',
                        comments: 'Comments',
                        photos: 'Photos',
                        uploadPhoto: 'Upload photo',
                        capturePhoto: 'Capture photo',
                        ticketDetails: 'Ticket details',
                        issueDetails: 'Issue details',
                        entitlementDetails: 'Entitlement details',
                        issueDiagnose: 'Issue diagnose',
                        issueRepair: 'Issue repair',
                        serviceSticker:
                            'Service sticker present & in good condition',
                        updatedBIOS: 'Updated BIOS',
                        housing: 'Housing',
                        screws: 'Screws',
                        hinges: 'Hinges',
                        dcIn: 'DC-in',
                        opratingSystem: 'Operating System',
                        battery: 'Battery',
                        display: 'Display',
                        touchScreen: 'Touchscreen',
                        tabletMode: 'Tablet mode',
                        keyboard: 'Keyboard',
                        touchPad: 'Touch pad',
                        webcam: 'Front facing camera (FFC)',
                        wfc: 'World facing camera (WFC)',
                        microphone: 'Microphone',
                        speaker: 'Sound through speakers',
                        audioJack: 'Sound through audio jack',
                        otherButton: 'Other buttons (if present)',
                        wifi: 'WiFi',
                        lanPort: 'LAN port',
                        securityBoot:
                            'Security Boot (Agree = Enabled, Disagree = Not enabled)',
                        usbA: 'USB-A ports',
                        usbC: 'USB-C ports',
                        hdmiPort: 'HDMI Port',
                        domainEnrollment: 'Domain enrollment',
                        systemCleaned: 'System cleaned',
                        issueQualityCheckSummary: 'Issue quality check summary',
                        overruleQualityCheck: 'Overrule Quality Check',
                        tableHeaders: {
                            section: 'Section',
                            agree: 'Agree',
                            disagree: 'Disagree',
                            na: 'N/A',
                        },
                        ifAvailable: ' (If available)',
                        message: {
                            success: 'Quality check success!',
                            failed: 'Quality check failed!',
                            assign2supervisor:
                                'Quality check failed for the 2nd time! Changed state to ASSIGNED_TO_SUPERVISOR!',
                        },
                    },
                },
                repair: {
                    titleCannotRepair: 'Why this repair cannot be finished?',
                    search: 'Search cause',
                    pictures: 'Pictures',
                    softwareDiagnostics: 'Software Diagnostics',
                    biosVersion: '- Bios version',
                    batteryState: '- Battery state',
                    rootCauses: 'Root causes',
                    diagnosticDescription: 'Diagnostic description',
                    technicianComments: 'Technician comments',
                    partsToChange: 'Parts to change',
                    technicianCommentsRepair: 'Technician Comments (Repair)',
                    additionalDescriptionTooltip:
                        'Additional Description: {{AD}}',
                    removePartTable: {
                        receivedPartNumber: 'Received part number',
                        partCode: 'Part code',
                        partName: 'Part name',
                        removedPartName: 'Removed part number',
                        removedPpid: 'Removed ppid',
                        notReplaced: 'Not replaced?',
                        doa: 'DOA',
                    },
                    button: {
                        cannotFinish: 'Repair cannot be finished',
                        cancel: 'Cancel',
                        repair: 'Device repaired',
                        finish: 'Finish',
                    },
                    message: {
                        notFound: 'Cannot find any root causes',
                        errorFromApi: 'An error occurred while fetching data',
                        requiredComment: 'Comment field is required',
                        requiredAllFields:
                            'Please fill all the required fields!',
                        duplicatedPpid: 'Duplicated PPID!',
                        samePpid: 'The same with received PPID',
                        createdDoaIssueSuccessfully:
                            'A DOA issue was created successfully',
                    },
                },
                triage: {
                    title: 'Triage for issue {{issueId}}',
                    message: 'Please run Software Diagnostics',
                    imagingStep:
                        'Please continue on Imaging Step for this issue',
                },
                images: {
                    title: 'Images for issue {{issueId}}',
                    imagefailed: 'Image Failed',
                    message: 'Do you want to cancel this issue?',
                    proceedIssue: 'Proceed Issue',
                    messageProceed:
                        'Are you sure you want to proceed this issue to QC?',
                },
            },
            dispatches: {
                dispatch: {
                    overview: {
                        id: `Id`,
                        Serial: `Serial`,
                        dispatchId: `Dispatch Id`,
                        Code: `Code`,
                        Status: `Status`,
                        State: `State`,
                        Branch: `Branch`,
                        Contact: `Contact`,
                        DPS: `DPS`,
                        Waybill: `Waybill`,
                        Created: `Created`,
                        Updated: `Updated`,
                        add_dispatch: `Add Dispatch`,
                        reference: `Reference`,
                        notes: `Notes`,
                        descriptionOfProblem: `Description of problem`,
                        createdBy: `Created by`,
                        serviceCenter: `Service Center`,
                        errorMessage: `Error Message`,
                        action: `Action`,
                        dispatchNumber: `Dispatch number`,
                        esdLocation: `ESD-Location`,
                        ticket: `Ticket`,
                        User: `User`,
                        CreatedManually: `Created Manually`,
                    },
                    detail: {
                        Dispatch: `Dispatch`,
                        showALl: `Show all`,
                        hideAll: `Hide all`,
                        ServiceTagDetails: `Service Tag Details`,
                        ModelDescription: `Model Description`,
                        ServiceTag: `Service Tag`,
                        Warranty: `Warranty`,
                        InWarranty: `In warranty`,
                        OutOfWarranty: `Out of warranty`,
                        DaysUntilExpiration: `Days until Expiration`,
                        WorkOrderInformation: `Work Order Information`,
                        Attachments: `Attachments`,
                        Customer: `Customer`,
                        Group: `Group`,
                        Technician: `Technician`,
                        ServiceLevelAgreement: `Service Level Agreement`,
                        Entitlement: `Entitlement`,
                        CustomerIdentifiedDamage: `Customer identified damage`,
                        DispatchInformation: `Dispatch Information`,
                        Reference: `Reference/PO# (optional)`,
                        ScheduleAlternateDispatchDate: `Schedule Alternate Dispatch Date`,
                        DispatchNumber: `Dispatch Number`,
                        ContactInformation: `Contact Information`,
                        PrimaryContactName: `Primary Contact Name`,
                        PrimaryContactPhone: `Primary Contact Phone #`,
                        PrimaryContactPhoneExt: `Primary Contact Phone Ext`,
                        AdditionalEmail: `Additional Email`,
                        AltContactName: `Alt. Contact Name`,
                        AltContactEmail: `Alt. Contact Email`,
                        AltContactPhone: `Alt Contact Phone #`,
                        AltContactPhoneExt: `Primary Contact Phone Ext`,
                        PartsDetail: `Parts Detail`,
                        PartCode: `Part Code`,
                        PartDescription: `Part Description`,
                        PartCategory: `Part Category`,
                        SerialNumber: `PPID/Serial Number`,
                        Quantity: `Quantity`,
                        CRU: `CRU/FRU`,
                        Component: `Component`,
                        Subcomponent: `Subcomponent`,
                        AddedBy: `Added By`,
                        EstimatedDeliveryDate: `Estimated Delivery Date`,
                        WaybillNumber: `Waybill Number`,
                        DpsNumber: 'DPS Number',
                        ProofOfDelivery: `Proof of Delivery`,
                        RequestTechnician: `Request on-site technician`,
                        NoteTechnician: `Note for on-site Technician`,
                        ShippingInformation: `Shipping Information`,
                        AddressName: `Address Name`,
                        Address: `Address`,
                        City: `City`,
                        StateProvince: `State / Province`,
                        ZipPostalCode: `Zip / Postal Code`,
                        Location: `Location`,
                        TimeZone: `Time Zone`,
                        DeliveryInformation: `Delivery Information`,
                        Status: `Status`,
                        Carrier: `Carrier`,
                        LastActivity: `Last Activity`,
                        StatusHistory: `Status History`,
                        UpdateTimeLocal: `Update Time Local`,
                        StatusDescription: `Status Description`,
                        UserName: `User Name`,
                        NoPartCode: `No part codes`,
                        partNo: `#Part`,
                        dispatchCode: `Dispatch code`,
                        region: `Region`,
                        serviceCenter: `Service center`,
                        dellTechDirectConnector: `Dell Tech Direct Connector`,
                        closeThisDispatch: `Close This Dispatch`,
                        confirmCloseDispatch: `Are you sure you want to close this dispatch? This cannot be undone`,
                    },
                    add: {
                        confirmAddDispatchContent: `Do you want to add dispatch for the Dell serial {{serial}}?`,
                        confirmAddDispatch: `Add Dispatch Confirmation`,
                        scanSerial: `Scan Serial`,
                        pageName: `Add Dell Dispatch`,
                        serviceCenter: `Service center`,
                        serviceCenterInput: `Select service center`,
                        service_center: `Service center`,
                        partCodes: `Part codes`,
                        description: `Troubleshooting Note`,
                        descriptionOfProblem: `Description Of Problem`,
                        troubleshootingNote: `Troubleshooting Note`,
                        filter_part_type: `Filter part type`,
                        filter_part_code: `Filter part code`,
                        filter_part_number: `Filter part number`,
                        filter_sku: `Filter SKU`,
                        dispatch_info: `Additional Dispatch Info`,
                        options: `Options`,
                        yes: `Yes`,
                        no: `No`,
                        request_complete_care: `Request Complete Care`,
                        request_return_to_depot: `Request Return To Depot`,
                        reference: `Reference ( Ticket no @ esd location )`,
                        ticketId: `Ticket Id`,
                        esdLocation: `ESD Location`,
                        rootCauses: `Root Causes`,
                        attachments: 'Attachments',
                        uploadPictures: 'Upload pictures',
                        uploadPicture: 'Upload Picture',
                        addPictures: 'Add pictures',
                    },
                    import: {
                        importLenovo: `Import Lenovo Dispatches`,
                        serial: `Serial`,
                        id: `ID`,
                        no: `No.`,
                        status: `Status`,
                        pageName: 'Dispatch Import/Export',
                    },
                },
            },
            components: {
                id: `Id`,
                Name: `Name`,
                PartNumber: `Part Number`,
                ItemNumber: `Item Number`,
                Description: `Description`,
                Code: `Part Code`,
                PartCode: `Part Name`,
                Type: `Type`,
                deviceName: `Device name`,
                count: `Quantity`,
                name: `Device name`,
                itemDescription: `Item Description`,
                oldest: `Oldest`,
                newest: `Newest`,
                inWarranty: `In warranty`,
                outOfWarranty: `Out of warranty`,
                expireInYearWarranty: `Expired this year`,
                active: `Active`,
                stock: `Stock`,
                other: `Other`,
                removeComponent: `Remove Component`,
                action: `Action`,
                linkComponent: `Link Component`,
                voteComponent: `Vote Component`,
                addComponents: `Add Components`,
                areUSure: `Are you sure to unlink this component?`,
            },
            product: {
                partCode: {
                    overview: {
                        Code: `Code`,
                        Name: `Name`,
                        Type: `Type`,
                        Model: `Models`,
                    },
                },
            },
            procurement: {
                purchaseOrder: {
                    overview: {
                        purchaseOrder: `Purchase order`,
                        supplier: `Supplier`,
                        entity: `Entity`,
                        deliveryStatus: `Delivery Status`,
                        contactName: `Contact name`,
                        orderDate: `Order Date`,
                        expectedDeliveryDate: `Expect Delivery Date`,
                        action: `Action`,
                    },
                    detail: {
                        id: `Id`,
                        productId: `Product Id`,
                        productNr: `Product Nr`,
                        price: `Price`,
                        qty: `Qty`,
                        delivered: `Delivered`,
                        supplierCode: `Supplier Code`,
                    },
                    orderLines: {
                        PurchaseOrderLine: `Purchase Order Line`,
                        PurchaseOrder: `Purchase Order`,
                        PurchaseOrderEntitlement: `Entitlement`,
                        PurchaseOrderSpecification: `Specification`,
                    },
                },
                orderConfirmation: {
                    overview: {
                        supplier: `Supplier`,
                        ourPO: `Our P.O`,
                        supplierOrder: `Supplier Order`,
                        orderDate: `Order Date`,
                        deliveryStatus: `Delivery Status`,
                        customer: `Customer`,
                        information: `Information`,
                        city: `City`,
                        amountExclVat: `Amount excl vat`,
                        receivedAPI: `Received API`,
                        status: `Status`,
                        responseCode: `Response code`,
                    },
                },
                procurementOrder: {
                    overview: {
                        orderId: `Order ID`,
                        supplier: `Supplier`,
                        ourPO: `Our P.O`,
                        supplierOrder: `Supplier Order`,
                        orderDate: `Order Date`,
                        deliveryStatus: `Delivery Status`,
                        customer: `Customer`,
                        information: `Information`,
                        city: `City`,
                        amountExclVat: `Amount excl vat`,
                        receivedAPI: `Received API`,
                        status: `Status`,
                        responseCode: `Response code`,
                    },
                    detail: {
                        orderId: 'Order ID',
                        supplier: 'Supplier',
                        ourPO: 'Our P.O',
                        supplierOrder: 'Supplier Order',
                        orderDate: 'Order Date',
                        status: 'Status',
                        deliveryStatus: 'Delivery Status',
                        responseCode: 'Response code',
                        information: 'Information',
                        city: 'City',
                        amountExclVat: 'Amount excl vat',
                        amountInclVat: 'Amount incl vat',
                        createdAt: 'Created at',
                        customerId: 'Customer',
                        descriptionResponseCode: 'Description response code',
                        termsOfPaymentText: 'Terms of payment text',
                        incotermsText: 'Incoterms text',
                        endUserName: 'Name',
                        endUserStreet: 'Street',
                        endUserPostalCode: 'Postal Code',
                        endUserCity: 'City',
                        endUserCountry: 'Country',
                        ZIP: 'ZIP Code',
                        name: 'Name',
                        Street: 'Street',
                        Country: 'Country',
                        CustContact: 'CustContact',
                        PartnerID: 'Partner ID',
                        address: 'Address',
                        postcode: 'Post Code',
                        poboxPostcode: 'Pobox Post Code',
                        phone: 'Phone',
                        fax: 'Fax',
                        bankAccount: 'Bank account',
                        vatNumber: 'VAT number',
                        lineNumber: 'Line number',
                        itemId: 'Item',
                        itemDescription: 'Item description',
                        manufacturerItemId: 'Manufacturer',
                        firstRequestedDeliveryDate: 'Requested delivery date',
                        price: 'Price',
                        lineAmount: 'Line amount',
                        quantityOrdered: 'Quantity ordered',
                        quantity: 'Qty',
                        atpCode: 'Atp code',
                        atpDate: 'Atp date',
                        quantityRemaining: 'Quantity remaining',
                        id: 'Id',
                    },
                },
                delivery: {
                    overview: {
                        supplier: `Supplier`,
                        route: `Route`,
                        dispatchNumber: `Dispatch Number`,
                        dispatchDate: `Dispatch Date`,
                        toName: `To Name`,
                        toCity: `To City`,
                        toCountry: `To Country`,
                        customer: `Customer`,
                        receivedAPI: `Received API`,
                    },
                    detail: {
                        orderedQty: `Ordered qty`,
                        sku: `SKU`,
                        supplierSku: `Supplier sku`,
                        description: `Description`,
                        vendorSku: `Vendor sku`,
                        qty: `Qty`,
                    },
                },
                invoice: {
                    overview: {
                        supplier: `Supplier`,
                        invoiceNumber: `Invoice number`,
                        invoiceDate: `Invoice date`,
                        invoiceExpirationDate: `Expiration Date`,
                        toNumber: `To Number`,
                        toName: `To Name`,
                        toCity: `To City`,
                        toCountry: `To Country`,
                        customer: `Customer`,
                        receivedAPI: `Received API`,
                        amountInclude: `Amount include`,
                        amountExclude: `Amount exclude`,
                    },
                },
            },
            administrator: {
                dataScheduler: {
                    overview: {
                        name: `Name`,
                        save: `Save`,
                        id: `Id`,
                        cron: `Cron`,
                        method: `Method`,
                        database: `Database`,
                    },
                    create: {
                        name: `Name`,
                        queryStatement: `Query Statement`,
                        emails: `Emails`,
                        storagePath: `Storage Path`,
                        cronPattern: `Cron Pattern`,
                        save: `Save`,
                        enable: `Enable`,
                        disable: `Disable`,
                        database: `Database`,
                    },
                },
                roles: {
                    permissions: `Permissions`,
                    name: `Name`,
                    users: `Users`,
                    create: `Create`,
                    guard_name: `Guard Name`,
                },
                roleDetail: {
                    name: `Name`,
                    guard_name: `Guard Name`,
                    permissions: `Permissions`,
                    save: `Save`,
                    data_not_found: `Data not found`,
                },
                serviceAccounts: {
                    id: `Id`,
                    name: `Name`,
                    secret: `Secret`,
                    revoked: `Revoked`,
                    created_at: `Created At`,
                    updated_at: `Updated At`,
                    create: `Create`,
                    actions: `Actions`,
                    description: `Description`,
                    contact: `Contact`,
                    contact_email: `Contact Email`,
                },
                serviceAccountDetail: {
                    name: `Name`,
                    client_id: `Client Id`,
                    client_secret: `Client Secret`,
                    revoked: `Revoked`,
                    revoke: `Revoke`,
                    created_at: `Created At`,
                    updated_at: `Updated At`,
                    create: `Create`,
                    save: `Save`,
                    actions: `Actions`,
                    description: `Description`,
                    contact: `Contact`,
                    contact_email: `Contact Email`,
                    whitelist: `Whitelist`,
                    token: `Token`,
                    generate: `Generate`,
                    role: `Role`,
                },
                definition: {
                    causes: {
                        overview: {
                            id: `Id`,
                            tid: `Tag`,
                            notes: `Troubleshooting notes`,
                            model: `Model`,
                            name: `Name`,
                            createdAt: `Created at`,
                            updatedAt: `Updated at`,
                            code: `Code`,
                            type: `Type`,
                            serializable: `Serializable`,
                            enabled: `Enabled?`,
                        },
                    },
                    regions: {
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            mainService: `Main service center`,
                            createdAt: `Created at`,
                            updatedAt: `Updated at`,
                            region: `Region`,
                            serviceCenter: `Service centers`,
                        },
                        edit: {
                            name: `Name`,
                            client_id: `Client Id`,
                            client_secret: `Client Secret`,
                            revoked: `Revoked`,
                            revoke: `Revoke`,
                            created_at: `Created At`,
                            updated_at: `Updated At`,
                            create: `Create`,
                            save: `Save`,
                            actions: `Actions`,
                            description: `Description`,
                            contact: `Contact`,
                            contact_email: `Contact Email`,
                            whitelist: `Whitelist`,
                            token: `Token`,
                            generate: `Generate`,
                            role: `Role`,
                            mainServiceCenter: `Main service center`,
                            dell_tech_direct_api_connector: `Dell Tech Direct API connector`,
                        },
                    },
                    serviceCenter: {
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            contactPerson: `Contact person`,
                            address: `Address`,
                            region: `Region`,
                            createdAt: `Created at`,
                            updatedAt: `Updated at`,
                            serviceCenter: `Service center`,
                            separateTriage: `Separate Triage`,
                        },
                        detail: {
                            serviceCenter: `Service center`,
                            team: `Teams`,
                            warehouseSetting: 'Warehouse setting',
                            separateTriage: `Separate Triage`,
                        },
                        form: {
                            region: `Region`,
                            address: `Address`,
                            contactPerson: `Contact person`,
                            name: `Name`,
                            description: `Description`,
                            save: `Save`,
                            dellBranch: `Dell branch`,
                            phoneNumber: `Phone Number`,
                            dellEmailDirection: `Sent dispatch emails to contact person`,
                            Easy4UID: 'Easy4U ID',
                            manualDispatchBranch: 'Manual dispatch branch',
                        },
                    },
                    team: {
                        overview: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            contactPerson: `Contact person`,
                            avatar: `Avatar`,
                            createdAt: `Created at`,
                            updatedAt: `Updated at`,
                            serviceCenter: `Service center`,
                        },
                        detail: {
                            id: `Id`,
                            name: `Name`,
                            description: `Description`,
                            contactPerson: `Contact person`,
                            avatar: `Avatar`,
                            createdAt: `Created at`,
                            updatedAt: `Updated at`,
                            serviceCenter: `Service center`,
                            teamMember: `Team members`,
                        },
                        create: {
                            contactPerson: `Contact person`,
                            name: `Name`,
                            description: `Description`,
                            save: `Save`,
                            serviceCenter: `Service center`,
                        },
                        edit: {
                            contactPerson: `Contact person`,
                            name: `Name`,
                            description: `Description`,
                            save: `Save`,
                            serviceCenter: `Service center`,
                        },
                    },
                    address: {
                        create: {
                            name: `Name`,
                            street_1: `Street 1`,
                            street_2: `Street 2`,
                            street_3: `Street 3`,
                            zipcode: `zipcode`,
                            city: `City`,
                            state: `State`,
                            iso_country_code: `Country`,
                            timezone_zone: `Timezone`,
                        },
                    },
                },
                workbench: {
                    id: `Id`,
                    createdAt: `Created at`,
                    updatedAt: `Updated at`,
                    name: `Name`,
                    serviceCenter: `Service center`,
                    location: `Location`,
                    save: `Save`,
                    add: `Add`,
                    delete: `Delete`,
                },
                dellStock: {
                    id: `Id`,
                    depot: `Depot`,
                    depot_name: `Depot Name`,
                    owner: `Owner`,
                    part_number: `Part Number`,
                    part_description: `Part Description`,
                    commodity: `Commodity`,
                    available: `Available`,
                    price_usd: `USD`,
                    price_eur: `EUR`,
                    price_gbp: `GBP`,
                    timestamp: `Timestamp`,
                },
                namingTag: {
                    vendor_id: `Vendor`,
                    id: `Id`,
                    name: `Name`,
                    device_tag: `Device Tag`,
                    device_name: `Device Name`,
                    component: `Component`,
                    component_id: `Component`,
                    device_tag_id: `Device Tag Id`,
                    action: `Action`,
                    save: `Save`,
                    delete: `Delete`,
                    revoke: `Revoke`,
                    apply: `Apply`,
                    add: `Add`,
                    saveBeforeApplyWarning: `All the changes must be saved before applying.`,
                },
                tooltip: {
                    id: `Id`,
                    model: 'Model',
                    property: 'Property',
                    tooltip: 'Tooltip',
                    add: `Add`,
                    save: `Save`,
                    delete: `Delete`,
                    pageUrl: `Page URL`,
                    componentId: `Component`,
                },
                product: {
                    id: `Id`,
                    name: `Name`,
                    sku: `SKU`,
                    code: `Code`,
                    created_by: `Created By`,
                    updated_by: `Updated By`,
                    brand_id: `Brand`,
                    created_at: `Created At`,
                    description: `Description`,
                },
                testData: {
                    diagnose: {
                        serial: 'Serial',
                        amount: 'Amount',
                        type: 'Type',
                        stage: 'Stage',
                        create_diagnose_for_testing:
                            'Create Diagnose For Testing',
                    },
                    save: `Save`,
                    overview: {
                        id: 'ID',
                        ticket_id: 'Ticket ID',
                        name: 'Name',
                        status: 'Status',
                        state: 'State',
                        bom_state: 'Bom State',
                        tracking_no: 'Tracking No',
                        action: 'Action',
                        accept_dispatch: 'Accept Dispatch',
                        create_shipment: 'Create Shipment Report',
                        create_shipment_successfully:
                            'Add dispatch shipment report successfully!',
                        accept_quotation: 'Accept Quotation',
                        accept_quotation_successfully:
                            'Accept Quotation Successfully',
                        deny_quotation: 'Deny Quotation',
                        deny_quotation_successfully:
                            'Deny Quotation Successfully',
                    },
                },
            },
            dispute: {
                remittance: {
                    import: `Import`,
                    fileUpload: `File Uploaded:`,
                    fileUploadButton: `Upload file`,
                    importedFile: `Imported File`,
                    oldestImport: `Oldest Import`,
                    latestImport: `Latest Import`,
                },
            },
            boms: {
                overview: {
                    boms: 'BOM',
                    id: 'ID',
                    serial: 'Serial',
                    issue: 'Issue ID',
                    state: 'State',
                    region: 'Region',
                    service_center: 'Service Center',
                    dispatch_code: 'Dispatch Code',
                    wms_order: 'WMS Order',
                    created_at: 'Created At',
                },
            },
            bom: {
                part_code: 'Part Code',
                qty: 'Qty',
                condition: 'Condition',
                stock_label: 'Stock Label',
                part_number: 'Part Number',
                sku: 'SKU',
                price: 'Price',
                dispatch_id: 'Dispatch ID',
                dispatch_code: 'Dispatch Code',
                dps_number: 'DPS Number',
                stock_level: 'Stock Level',
                external_order_id: 'External Order Id',
                wms_order: 'WMS Order',
                state: 'State',
                serial: 'Serial',
                issue_id: 'Issue ID',
                bom_state: 'BOM state',
                device_model_name: 'Device model name',
                bom_title: 'BOM Information',
                set_sku_to_bom_msg:
                    'Do you want to set {{selectSku}} SKU to this BOM?',
            },
            production: {
                orders: {
                    id: 'ID',
                    orderNumber: 'Order Number',
                    productionProjectId: 'Production Project Id',
                    addonId: 'Addon Id',
                    image: 'Image',
                    imageId: 'Image Id',
                    projectNumber: 'Project Nummer',
                    serviceLocation: 'Service Location',
                    Qty: 'Qty',
                    deliveryDate: 'Delivery Date',
                    created: 'Created At',
                    updated: 'Updated At',
                    scanOderNumber: 'Scan order number',
                    scanSerial: 'Scan serial',
                    enrollSerial: 'Enroll Serial',
                    details: 'Details',
                    qty: 'Qty',
                    assigned: 'Assigned',
                    state: 'State',
                },
                orderLine: {
                    id: 'ID',
                    orderId: 'Production Order Id',
                    lineNr: 'Line Number',
                    Serial: 'Serial',
                    created: 'Created At',
                    updated: 'Updated At',
                    deleted: 'Deleted',
                    deviceId: 'Device Id',
                    productId: 'Product Id',
                    projectDeviceId: 'Project Device Id',
                    validated: 'Validated',
                    action: 'Action',
                    state: 'State',
                    server: 'Server',
                    bios: 'Bios',
                    battery: 'Battery',
                },
                addons: {
                    id: 'ID',
                    Addons: 'Addons',
                    name: 'Name',
                    version: 'Version',
                    filename: 'Filename',
                    project: 'Project',
                    createdBy: 'Created By',
                    updatedBy: 'Updated By',
                    created: 'Created At',
                    updated: 'Updated At',
                    addParam: 'Add Param',
                    Create: 'Create',
                    Cancel: 'Cancel',
                    save: 'Save',
                    required: 'Required',
                    type: 'Type',
                    regex: 'Regex',
                    key: 'Key',
                    display_name: 'Display Name',
                    datatype: 'Data Type',
                    default: 'Default',
                    help: 'Help',
                },
                servers: {
                    id: 'ID',
                    hostname: 'Host Name',
                    name: 'Name',
                    organisation: 'Organisation',
                    lastip: 'Last IP',
                    lastseen: 'Last Seen',
                },
                images: {
                    id: 'ID',
                    company_id: 'Company',
                    name: 'Name',
                    type: 'Type',
                    language_code: 'Language Code',
                    versions: 'Versions',
                    lastseen: 'Last Seen',
                    production_image_id: 'Production Image ID',
                    version: 'Version',
                    version_minor: 'Version Minor',
                    edition: 'Edition',
                },
                projects: {
                    id: 'ID',
                    project_nr: 'Project Number',
                    entity_tid: 'Entity Tid',
                    service_center_id: 'Service Center Id',
                    external_id: 'External Id',
                    production_image_id: 'Image Id',
                    active: 'Active',
                    intune_group_tag: 'Intune Group Tag',
                    lastseen: 'Last Seen',
                    os_version: 'OS Version',
                    language: 'Language',
                    target_group: 'Target Group',
                    delivery_date: 'Delivery Date',
                    state: 'State',
                    created: 'Created At',
                    addon_id: 'Addon Id',
                    params: 'Params',
                    assignAddon: 'Assign Addon',
                    editProject: 'Edit Project',
                    importProject: 'Import Project',
                    projectAddon: 'Project Addons',
                    assignAddonToProject: 'Assign Addon to Project',
                    action: 'Action',
                    deleteAddon: 'Do you want delete this addon?',
                    editAddon: 'Edit Project Addon',
                },
            },
            causes: {
                validateForAllModels: 'You must select at least one model!',
                duplicatedModels:
                    'The following models: {{models}} are already associated with Cause ID: {{cause_id}} with the same tag ({{tag}})',
            },
        },
        validation: {
            emailRequired: `Email is a required field.`,
            emailInvalid: `Email is an invalid format.`,
            emailNotMatch: `Only users @rentcompany.nl / .be can be invited.`,
            phoneInvalid: `Phone number must only contains number, space & bracket. Ex: (12) 3456 789.`,
            ipInvalid: `Invalid IP`,
            multipleIpNotMatch: `Please enter valid IPv4 and use commas separator for multiple IP`,
            serialNumberRequired: `Serial number is a required field.`,
            serialNumberNotMatch: `Serial number has invalid format.`,
            serviceCenterRequired: `Service center is a required field.`,
            multipleEmail: `Please enter valid email and use semicolon separator for multiple email.`,
            cron: `Please enter valid cron pattern.`,
            selectStatement: `Please enter 'SELECT' statement.`,
            integer: `Value must be integer`,
            workbenchLocation: `Workbench location has invalid format.`,
        },
        table: {
            name: 'Name',
            partName: 'Part name',
            partCode: 'Part code',
            action: 'Action',
            actions: 'Actions',
            sku: 'SKU',
        },
    },
}
