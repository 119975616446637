import {
    PERMISSION_ADMINISTRATOR,
    PERMISSION_ADMINISTRATOR_DATASCHEDULER,
    PERMISSION_ADMINISTRATOR_DEFINITION,
    PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
    PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
    PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
    PERMISSION_ADMINISTRATOR_NAMING_TAG,
    PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
    PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
    PERMISSION_ADMINISTRATOR_ROLE,
    PERMISSION_ADMINISTRATOR_SYSTEM,
    PERMISSION_ADMINISTRATOR_SYSTEM_API,
    PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG,
    PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    PERMISSION_ADMINISTRATOR_TEAM,
    PERMISSION_ADMINISTRATOR_TEST_DATA,
    PERMISSION_ADMINISTRATOR_USER,
    PERMISSION_ADMINISTRATOR_USER_DISABLED,
    PERMISSION_CLAIM_DISPUTE,
    PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
    PERMISSION_CLAIM_INVOICE,
    PERMISSION_CLAIM_RENUMERATION,
    PERMISSION_CLAIM_RENUMERATION_CHECK,
    PERMISSION_DASHBOARD,
    PERMISSION_FLEET_IMPORT_DEVICES,
    PERMISSION_FLEET_MODEL,
    PERMISSION_FLEET_OVERVIEW,
    PERMISSION_FLEET_OVERVIEW_CHROME,
    PERMISSION_FLEET_OVERVIEW_VENDOR,
    PERMISSION_FLEET_OVERVIEW_WINDOW,
    PERMISSION_FLEET_SERIAL,
    PERMISSION_FLEET_SWAP,
    PERMISSION_ISSUE_ARCHIVE,
    PERMISSION_ISSUE_ARCHIVE_IMAGES,
    PERMISSION_ISSUE_BOM_OVERVIEW,
    PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT,
    PERMISSION_ISSUE_DISPATCH_VIEW,
    PERMISSION_ISSUE_OVERVIEW,
    PERMISSION_ISSUE_RACKSCANS,
    PERMISSION_ISSUE_RACKSCANS_SCAN,
    PERMISSION_ISSUE_RACKSCANS_TICKETS,
    PERMISSION_ISSUE_TICKETS,
    PERMISSION_KNOWLEDGE,
    PERMISSION_KNOWLEDGE_OVERVIEW,
    PERMISSION_PART,
    PERMISSION_PART_COMPONENT,
    PERMISSION_PART_PARTCODE,
    PERMISSION_PART_PRODUCT,
    PERMISSION_PROCUREMENT,
    PERMISSION_PROCUREMENT_DELIVERY,
    PERMISSION_PROCUREMENT_INVOICE,
    PERMISSION_PROCUREMENT_ORDER,
    PERMISSION_PROCUREMENT_ORDERCONFIRMATION,
    PERMISSION_PROCUREMENT_PURCHASEORDER,
    PERMISSION_PROCUREMENT_RMA,
    PERMISSION_PRODUCTION,
} from '~/constants/permissions'
import {
    ADMINISTRATOR_DATASCHEDULER,
    ADMINISTRATOR_DEFINITION_CAUSE,
    ADMINISTRATOR_DEFINITION_REGIONS,
    ADMINISTRATOR_DEFINITION_SERVICECENTER,
    ADMINISTRATOR_DEFINITION_TEAM,
    ADMINISTRATOR_DEFINITION_WORKBENCH,
    ADMINISTRATOR_NAMING_TAG,
    ADMINISTRATOR_ROLE,
    ADMINISTRATOR_SYSTEM_API,
    ADMINISTRATOR_SYSTEM_ERRORLOG,
    ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
    ADMINISTRATOR_TEST_DATA_DIAGNOSE,
    ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT,
    ADMINISTRATOR_TOOLTIP,
    ADMINISTRATOR_USER,
    ADMINISTRATOR_USER_DISABLED,
    CLAIM_DISPUTE,
    CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
    CLAIM_INVOICE,
    CLAIM_RENUMERATION,
    DASHBOARD,
    FLEET_IMPORT_DEVICES,
    FLEET_MODEL,
    FLEET_OVERVIEW,
    FLEET_OVERVIEW_CHROME,
    FLEET_OVERVIEW_VENDOR,
    FLEET_OVERVIEW_WINDOW,
    FLEET_SERIAL,
    FLEET_SWAP,
    ISSUE_ARCHIVE,
    ISSUE_ARCHIVE_IMAGE,
    ISSUE_BOM,
    ISSUE_DISPATCH,
    ISSUE_DISPATCH_COMPONENT,
    ISSUE_DISPATCH_IMPORT_EXPORT,
    ISSUE_ISSUE,
    ISSUE_OVERVIEW,
    ISSUE_RACKSCAN_SCAN,
    ISSUE_RACKSCAN_TICKET,
    KNOWLEDGE,
    PART_COMPONENT,
    PART_DELL_STOCK,
    PART_PARTCODE,
    PART_PRODUCT,
    PART_USAGE,
    PROCUREMENT_DELIVERY,
    PROCUREMENT_INVOICE,
    PROCUREMENT_ORDERCONFIRMATION,
    PROCUREMENT_ORDERS,
    PROCUREMENT_PURCHASEORDER,
    PROCUREMENT_RMA,
    PRODUCTION_ADD_ONS,
    PRODUCTION_ORDERS,
    PRODUCTION_ORGANIZATION,
    PRODUCTION_PROJECTS,
    PRODUCTION_SERVERS,
    PRODUCTION_SYSTEMIMAGE,
} from '~/constants/Routes'

export const MENU_ITEMS = [
    {
        title: 'Dashboard',
        url: DASHBOARD,
        icon: 'flutter_dash',
        permission: PERMISSION_DASHBOARD,
    },
    {
        title: 'Issues',
        url: ISSUE_ISSUE,
        icon: 'bug_report',
        permission: PERMISSION_ISSUE_OVERVIEW,
        children: [
            {
                title: 'Tickets',
                url: ISSUE_OVERVIEW,
                icon: 'dvr',
                permission: PERMISSION_ISSUE_TICKETS,
            },
            {
                title: 'Issues',
                url: ISSUE_ISSUE,
                icon: 'pest_control',
                permission: PERMISSION_ISSUE_OVERVIEW,
                children: [
                    {
                        title: 'BOM',
                        url: ISSUE_BOM,
                        icon: 'monetization_on',
                        permission: PERMISSION_ISSUE_BOM_OVERVIEW,
                    },
                ],
            },
            {
                title: 'Dispatches',
                url: ISSUE_DISPATCH,
                icon: 'send_time_extension',
                permission: PERMISSION_ISSUE_DISPATCH_VIEW,
                children: [
                    {
                        title: 'Component',
                        url: ISSUE_DISPATCH_COMPONENT,
                        icon: 'extension',
                        permission: PERMISSION_ISSUE_DISPATCH_VIEW,
                    },
                    {
                        title: 'Import/Export',
                        url: ISSUE_DISPATCH_IMPORT_EXPORT,
                        icon: 'upload',
                        permission: PERMISSION_ISSUE_DISPATCH_IMPORT_EXPORT,
                    },
                ],
            },
            {
                title: 'Archive',
                url: ISSUE_ARCHIVE,
                icon: 'inventory_2',
                permission: PERMISSION_ISSUE_ARCHIVE,
                children: [
                    {
                        title: 'Images',
                        url: ISSUE_ARCHIVE_IMAGE,
                        icon: 'image',
                        permission: PERMISSION_ISSUE_ARCHIVE_IMAGES,
                    },
                ],
            },
            {
                title: 'Rackscans',
                url: ISSUE_RACKSCAN_TICKET,
                icon: 'domain',
                permission: PERMISSION_ISSUE_RACKSCANS,
                children: [
                    {
                        title: 'Tickets',
                        url: ISSUE_RACKSCAN_TICKET,
                        icon: 'confirmation_number',
                        permission: PERMISSION_ISSUE_RACKSCANS_TICKETS,
                    },
                    {
                        title: 'Scans',
                        url: ISSUE_RACKSCAN_SCAN,
                        icon: 'barcode',
                        permission: PERMISSION_ISSUE_RACKSCANS_SCAN,
                    },
                ],
            },
        ],
    },
    {
        title: 'Fleet',
        url: FLEET_OVERVIEW,
        icon: 'devices',
        permission: 'fleet.overview',
        children: [
            {
                title: 'Overview',
                url: FLEET_OVERVIEW,
                icon: 'display_settings',
                permission: PERMISSION_FLEET_OVERVIEW,
                children: [
                    {
                        title: 'Vendor',
                        icon: 'screenshot_monitor',
                        url: FLEET_OVERVIEW_VENDOR,
                        permission: PERMISSION_FLEET_OVERVIEW_VENDOR,
                    },
                    {
                        title: 'Windows',
                        url: FLEET_OVERVIEW_WINDOW,
                        icon: 'window',
                        permission: PERMISSION_FLEET_OVERVIEW_WINDOW,
                    },
                    {
                        title: 'Chromebooks',
                        url: FLEET_OVERVIEW_CHROME,
                        icon: 'cast_connected',
                        permission: PERMISSION_FLEET_OVERVIEW_CHROME,
                    },
                ],
            },
            {
                title: 'Serials',
                url: FLEET_SERIAL,
                icon: 'pin',
                permission: PERMISSION_FLEET_SERIAL,
            },
            {
                title: 'Import devices',
                url: FLEET_IMPORT_DEVICES,
                icon: 'upload',
                permission: PERMISSION_FLEET_IMPORT_DEVICES,
            },
            {
                title: 'Models',
                url: FLEET_MODEL,
                icon: 'browser_updated',
                permission: PERMISSION_FLEET_MODEL,
            },
            {
                title: 'Swaps',
                url: FLEET_SWAP,
                icon: 'repeat',
                permission: PERMISSION_FLEET_SWAP,
            },
        ],
    },
    {
        title: 'Parts',
        url: PART_COMPONENT,
        icon: 'category',
        permission: PERMISSION_PART,
        children: [
            {
                title: 'Components',
                url: PART_COMPONENT,
                icon: 'extension',
                permission: PERMISSION_PART_COMPONENT,
            },
            {
                title: 'Usage',
                url: PART_USAGE,
                icon: 'data_usage',
                permission: PERMISSION_PART_COMPONENT,
            },
            {
                title: 'Part codes',
                url: PART_PARTCODE,
                icon: 'list',
                permission: PERMISSION_PART_PARTCODE,
            },
            {
                title: 'Products',
                url: PART_PRODUCT,
                icon: 'dataset',
                permission: PERMISSION_PART_PRODUCT,
            },
            {
                title: 'Dell stock',
                url: PART_DELL_STOCK,
                icon: 'inventory_2',
                permission: PERMISSION_PART,
            },
        ],
    },
    {
        title: 'Knowledge',
        url: KNOWLEDGE,
        icon: 'school',
        permission: PERMISSION_KNOWLEDGE,
        children: [
            {
                title: 'Overview',
                url: KNOWLEDGE,
                icon: 'dvr',
                permission: PERMISSION_KNOWLEDGE_OVERVIEW,
            },
        ],
    },
    {
        title: 'Claims',
        url: CLAIM_RENUMERATION,
        icon: 'sms_failed',
        permission: PERMISSION_CLAIM_RENUMERATION,
        children: [
            {
                title: 'Renumeration check ',
                url: CLAIM_RENUMERATION,
                icon: 'content_paste',
                permission: PERMISSION_CLAIM_RENUMERATION_CHECK,
            },
            {
                title: 'Claim Invoices',
                url: CLAIM_INVOICE,
                icon: 'ballot',
                permission: PERMISSION_CLAIM_INVOICE,
            },
            {
                title: 'Disputes',
                url: CLAIM_DISPUTE,
                icon: 'chat',
                permission: PERMISSION_CLAIM_DISPUTE,
                children: [
                    {
                        title: 'Import Remittance',
                        url: CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
                        icon: 'ballot',
                        permission:
                            PERMISSION_CLAIM_DISPUTE_IMPORT_DISPATCH_REMITTANCE,
                    },
                ],
            },
        ],
    },
    {
        title: 'Procurement',
        url: PROCUREMENT_ORDERS,
        icon: 'receipt_long',
        permission: PERMISSION_PROCUREMENT,
        children: [
            {
                title: 'Orders',
                url: PROCUREMENT_ORDERS,
                icon: 'fact_check',
                permission: PERMISSION_PROCUREMENT_ORDER,
            },
            {
                title: 'Purchase Orders ',
                url: PROCUREMENT_PURCHASEORDER,
                icon: 'speaker_notes',
                permission: PERMISSION_PROCUREMENT_PURCHASEORDER,
            },
            {
                title: 'Order Confirmations',
                url: PROCUREMENT_ORDERCONFIRMATION,
                icon: 'fact_check',
                permission: PERMISSION_PROCUREMENT_ORDERCONFIRMATION,
            },
            {
                title: 'Invoices',
                url: PROCUREMENT_INVOICE,
                icon: 'receipt',
                permission: PERMISSION_PROCUREMENT_INVOICE,
            },
            {
                title: 'Deliveries',
                url: PROCUREMENT_DELIVERY,
                icon: 'local_shipping',
                permission: PERMISSION_PROCUREMENT_DELIVERY,
            },
            {
                title: 'RMA',
                url: PROCUREMENT_RMA,
                icon: 'assignment_return',
                permission: PERMISSION_PROCUREMENT_RMA,
            },
        ],
    },
    {
        title: 'Production',
        url: PRODUCTION_SYSTEMIMAGE,
        icon: 'precision_manufacturing',
        permission: PERMISSION_PRODUCTION,
        children: [
            {
                title: 'System Images',
                url: PRODUCTION_SYSTEMIMAGE,
                icon: 'install_desktop',
                permission: PERMISSION_PRODUCTION,
            },
            {
                title: 'Organizations',
                url: PRODUCTION_ORGANIZATION,
                icon: 'cast_for_education',
                permission: PERMISSION_PRODUCTION,
            },
            {
                title: 'Orders',
                url: PRODUCTION_ORDERS,
                icon: 'list_alt',
                permission: PERMISSION_PRODUCTION,
            },
        ],
    },
    {
        title: 'Administrator',
        url: ADMINISTRATOR_USER,
        icon: 'settings',
        permission: PERMISSION_ADMINISTRATOR,
        children: [
            {
                title: 'Users',
                url: ADMINISTRATOR_USER,
                icon: 'person',
                permission: PERMISSION_ADMINISTRATOR_USER,
                children: [
                    {
                        title: 'Disabled Users',
                        url: ADMINISTRATOR_USER_DISABLED,
                        icon: 'person_off',
                        permission: PERMISSION_ADMINISTRATOR_USER_DISABLED,
                    },
                ],
            },
            {
                title: 'Roles',
                url: ADMINISTRATOR_ROLE,
                icon: 'admin_panel_settings',
                permission: PERMISSION_ADMINISTRATOR_ROLE,
            },
            {
                title: 'System',
                url: ADMINISTRATOR_SYSTEM_ERRORLOG,
                icon: 'terminal',
                permission: PERMISSION_ADMINISTRATOR_SYSTEM,
                children: [
                    {
                        title: 'Error log',
                        url: ADMINISTRATOR_SYSTEM_ERRORLOG,
                        icon: 'report',
                        permission: PERMISSION_ADMINISTRATOR_SYSTEM_ERRORLOG,
                    },
                    {
                        title: "API's",
                        url: ADMINISTRATOR_SYSTEM_API,
                        icon: 'api',
                        permission: PERMISSION_ADMINISTRATOR_SYSTEM_API,
                    },
                    {
                        title: 'Service accounts',
                        url: ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
                        icon: 'key',
                        permission:
                            PERMISSION_ADMINISTRATOR_SYSTEM_SERVICE_ACCOUNT,
                    },
                ],
            },
            {
                title: 'Data Scheduler',
                url: ADMINISTRATOR_DATASCHEDULER,
                icon: 'export_notes',
                permission: PERMISSION_ADMINISTRATOR_DATASCHEDULER,
            },
            {
                title: 'Definitions',
                url: ADMINISTRATOR_DEFINITION_CAUSE,
                icon: 'table_view',
                permission: PERMISSION_ADMINISTRATOR_DEFINITION,
                children: [
                    {
                        title: 'Causes',
                        url: ADMINISTRATOR_DEFINITION_CAUSE,
                        icon: 'emergency_home',
                        permission: PERMISSION_ADMINISTRATOR_DEFINITION_CAUSE,
                    },
                    {
                        title: 'Regions',
                        url: ADMINISTRATOR_DEFINITION_REGIONS,
                        icon: 'public',
                        permission: PERMISSION_ADMINISTRATOR_TEAM,
                    },
                    {
                        title: 'Service Centers',
                        url: ADMINISTRATOR_DEFINITION_SERVICECENTER,
                        icon: 'support_agent',
                        permission:
                            PERMISSION_ADMINISTRATOR_DEFINITION_SERVICECENTER,
                    },
                    {
                        title: 'Teams',
                        url: ADMINISTRATOR_DEFINITION_TEAM,
                        icon: 'groups',
                        permission: PERMISSION_ADMINISTRATOR_TEAM,
                    },
                    {
                        title: 'Workbenches',
                        url: ADMINISTRATOR_DEFINITION_WORKBENCH,
                        icon: 'event_seat',
                        permission:
                            PERMISSION_ADMINISTRATOR_DEFINITION_WORKBENCH,
                    },
                    {
                        title: 'Naming tags',
                        url: ADMINISTRATOR_NAMING_TAG,
                        icon: 'local_offer',
                        permission: PERMISSION_ADMINISTRATOR_NAMING_TAG,
                    },
                    {
                        title: 'Tooltips',
                        url: ADMINISTRATOR_TOOLTIP,
                        icon: 'info',
                        permission: PERMISSION_ADMINISTRATOR_DATASCHEDULER,
                    },
                ],
            },
            {
                title: 'Test Data',
                url: ADMINISTRATOR_TEST_DATA_DIAGNOSE,
                icon: 'addchart',
                permission: PERMISSION_ADMINISTRATOR_TEST_DATA,
                children: [
                    {
                        title: 'Diagnose',
                        url: ADMINISTRATOR_TEST_DATA_DIAGNOSE,
                        permission: PERMISSION_ADMINISTRATOR_TEST_DATA,
                    },
                    {
                        title: 'Dispatch Shipment Report',
                        url: ADMINISTRATOR_TEST_DATA_DISPATCH_SHIPMENT_REPORT,
                        permission: PERMISSION_ADMINISTRATOR_TEST_DATA,
                    },
                ],
            },
            {
                title: 'Production',
                url: PRODUCTION_SERVERS,
                icon: 'precision_manufacturing',
                permission: PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                children: [
                    {
                        title: 'Servers',
                        url: PRODUCTION_SERVERS,
                        icon: 'storage',
                        permission: PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                    },
                    {
                        title: 'Add-ons',
                        url: PRODUCTION_ADD_ONS,
                        icon: 'extension',
                        permission: PERMISSION_ADMINISTRATOR_PRODUCTION_ADD_ONS,
                    },
                    {
                        title: 'Projects',
                        url: PRODUCTION_PROJECTS,
                        icon: 'grading',
                        permission: PERMISSION_ADMINISTRATOR_PRODUCTION_SERVERS,
                    },
                ],
            },
        ],
    },
]
